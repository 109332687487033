import axios from 'axios';
import { API_URL } from '../api';

const fetchSurveyors = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/surveyors/${id}`, config);

  if (response.data) {
    localStorage.setItem('surveyors', JSON.stringify(response.data));
  }
  return response.data;
};

const fetchUnverifiedSurveyors = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/surveyors/pending/${id}`, config);

  if (response.data) {
    localStorage.setItem('surveyors', JSON.stringify(response.data));
  }
  return response.data;
};

const surveyorServices = {
  fetchSurveyors,
  fetchUnverifiedSurveyors,
};

export default surveyorServices;
