import axios from 'axios';
import { API_URL } from '../api';

const getAllTitlePerfectionPrice = async (token, adminId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/titlePerfection/${adminId}`, config);
  return response.data;
};

const addTitlePerfectionPrice = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(`${API_URL}/titlePerfection/${data.adminId}`, data, config);
  return response.data;
};

const editTitlePerfectionPrice = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(`${API_URL}/titlePerfection/${data.State}/${data.adminId}`, data, config);
  return response.data;
};

const getTitlePerfectionPrice = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/titlePerfection/${data.state}`, config);
  return response.data;
};
const titlePerfectionService = {
  getAllTitlePerfectionPrice,
  addTitlePerfectionPrice,
  editTitlePerfectionPrice,
  getTitlePerfectionPrice,
};
export default titlePerfectionService;
