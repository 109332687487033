import React, { useEffect } from 'react';
import { Box, Button, Chip, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAgent, reset, updateAgentStatus, unVerifyAgent } from '../features/agent/agentSlice';
import Loader from '../components/loader/Loader';

const AgentDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const verifiedImg = '/assets/icons/verified.png';
  const unverifiedImg = '/assets/icons/unverified.png';
  const pendingImg = '/assets/icons/pending.png';
  const { agent, isLoading, isError, isSuccess, message } = useSelector((state) => state.agent);
  const { agentId } = useParams();

  useEffect(() => {
    dispatch(getAgent(agentId));
    reset();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(reset());
    }, 3000);
  }, []);

  const HandleVerify = () => {
    Swal.fire({
      title: 'Do you want to Verify this user?',
      showCancelButton: true,
      confirmButtonText: 'Verify',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(updateAgentStatus(agentId));
      }
    });
  };

  const HandleUnverify = () => {
    Swal.fire({
      title: 'Do you want to Unverify this user?',
      showCancelButton: true,
      confirmButtonText: 'Unverify',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(unVerifyAgent(agentId));
      }
    });
  };

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          window.location.reload(false);
          dispatch(reset());
        },
      });
    }

    if (isSuccess && message.includes('This Agent has now been Verified')) {
      navigate('/dashboard/verifiedAgents');
      dispatch(reset());
    }
    if (isSuccess && message.includes('This Agent has now been Unverified')) {
      navigate('/dashboard/unVerifiedAgents');
      dispatch(reset());
    }
  }, [isError, isSuccess, message, agent]);

  return (
    <>
      <Helmet>
        <title> Document | Lock.your.Land Dashboard </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Agent's Details
          </Typography>
        </Stack>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            {agent && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={7} lg={7} xl={7}>
                  <Box className="">
                    <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                      <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                        Owners Information: <br />
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                          <p className="text-blue-900">
                            Firstname: <br /> <span className="font-bold">{agent?.Firstname}</span>
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <p className="text-blue-900">
                            Lastname: <br /> <span className="font-bold">{agent?.Lastname}</span>
                          </p>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                          <p className="text-blue-900">
                            Phone: <br /> <span className="font-bold">{agent?.Phone}</span>
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <p className="text-blue-900">
                            Email: <br /> <span className="font-bold">{agent?.Email}</span>
                          </p>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                          <p className="text-blue-900">
                            Address: <br /> <span className="font-bold">{agent?.Address}</span>
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <p className="text-blue-900">
                            NIN: <br /> <span className="font-bold">{agent?.NIN}</span>
                          </p>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={5} lg={5} xl={5}>
                  <Box className="">
                    <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                      <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                        License Information: <br />
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                          <p className="text-blue-900">
                            License Number: <br />{' '}
                            <span className="font-bold">{agent?.AgentLicense || 'Not Assigned Yet'}</span>
                          </p>
                        </Grid>

                        {agent?.Company && (
                          <Grid item xs={12} sm={12} md={6}>
                            <p className="text-blue-900">
                              Company: <br /> <span className="font-bold">{agent?.Company || 'Not Assigned Yet'}</span>
                            </p>
                          </Grid>
                        )}

                        <Grid item xs={12} sm={12} md={6}>
                          <p className="text-blue-900">
                            Application Date: <br />{' '}
                            <span className="font-bold">
                              {agent?.createdAt
                                ? new Date(agent?.createdAt).toLocaleString('en-US', {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric',
                                  })
                                : null}
                            </span>
                          </p>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                          <p className="text-blue-900">Verification Status</p>
                          <Chip
                            label={
                              agent?.Verified === 'Yes' ? 'Verified' : agent?.Verified === 'No' ? 'Pending' : 'Failed'
                            }
                            size="small"
                            color={
                              agent?.Verified === 'Yes' ? 'success' : agent?.Verified === 'No' ? 'warning' : 'error'
                            }
                            icon={
                              <img
                                src={
                                  agent?.Verified === 'Yes'
                                    ? verifiedImg
                                    : agent?.Verified === 'No' && agent?.VerifiedLicense === true
                                    ? pendingImg
                                    : unverifiedImg
                                }
                                alt="verified img"
                                className="w-4 h-4"
                              />
                            }
                          />
                        </Grid>

                        <Box className=" p-3 space-x-2">
                          <Button
                            variant="outlined"
                            size="medium"
                            color="success"
                            sx={{
                              textTransform: 'none',
                              fontWeight: 'bold',
                            }}
                            onClick={HandleVerify}
                          >
                            {isLoading ? 'Please wait...' : 'Verify User'}
                          </Button>
                          <Button
                            variant="outlined"
                            size="medium"
                            color="error"
                            sx={{
                              textTransform: 'none',
                              fontWeight: 'bold',
                            }}
                            onClick={HandleUnverify}
                          >
                            {isLoading ? 'Please wait...' : 'Unverify User'}
                          </Button>
                        </Box>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default AgentDetails;
