import axios from 'axios';
import { API_URL } from '../api';

const logout = async () => {
  localStorage.removeItem('Adminuser');
};

export const logoutError = () => {
  localStorage.removeItem('Adminuser');
};

const login = async (userData) => {
  const response = await axios.post(`${API_URL}/admin/login`, userData);

  if (response.data) {
    localStorage.setItem('Adminuser', JSON.stringify(response.data));
  }
  return response.data;
};

const authService = {
  logout,
  login,
};

export default authService;
