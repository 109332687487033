import React, { useEffect } from 'react';
import { Box, Chip, Container, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUnverifiedUsers, reset } from '../features/users/usersSlice';
import Loader from '../components/loader';

const UserPage = () => {
  const dispatch = useDispatch();
  const pendingImg = '/assets/icons/pending.png';
  const unverifiedImg = '/assets/icons/unverified.png';
  const viewImg = '/assets/icons/search.png';
  const notFound = '/assets/images/covers/notfound.svg';
  const { users, isLoading, isError, message } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(fetchUnverifiedUsers());
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          reset();
        },
      });
    }
  }, [isError, message]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'Firstname',
      headerName: 'Firstname',
      width: 120,
    },
    {
      field: 'Lastname',
      headerName: 'Lastname',
      width: 120,
    },
    {
      field: 'Email',
      headerName: 'Email',
      width: 200,
    },
    {
      field: 'Gender',
      headerName: 'Gender',
      width: 120,
    },

    {
      field: 'Status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Chip
              variant="contained"
              color={`${
                params.row.Status === 'Not Verified' ? 'error' : params.row.Status === 'pending' ? 'warning' : null
              }`}
              label={`${
                params.row.Status === 'Not Verified'
                  ? 'Not Verified'
                  : params.row.Status === 'pending'
                  ? 'pending'
                  : null
              }`}
              icon={
                <img
                  src={
                    params.row.Status === 'Not Verified'
                      ? unverifiedImg
                      : params.row.Status === 'pending'
                      ? pendingImg
                      : null
                  }
                  alt="verified_img"
                  className="h-3 w-3"
                />
              }
              size="small"
              sx={{ cursor: 'pointer' }}
            />
          </>
        );
      },
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/dashboard/users/user/${params.row.Userid}`}>
              <Chip
                variant="contained"
                color="info"
                label="View"
                icon={<img src={viewImg} alt="verified_img" className="h-4 w-4" />}
                size="small"
                sx={{ cursor: 'pointer' }}
              />
            </Link>
          </>
        );
      },
    },
  ];

  const rows = [];
  users?.map((user, index) => {
    return rows.push({
      Userid: user._id,
      id: index + 1,
      Firstname: user.Firstname,
      Lastname: user.Lastname,
      Email: user.Email,
      Phone: user.Phone,
      Gender: user.Gender,
      Status: user.VerifiedUser,
    });
  });
  console.log(rows);
  return (
    <>
      <Helmet>
        <title> User | Lock.your.Land Dashboard </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Verified Users
          </Typography>
        </Stack>
        {isLoading ? (
          <Box sx={{ height: 400, width: '100%' }} className="flex items-center justify-center">
            <Loader />
          </Box>
        ) : (
          <Box sx={{ height: 400, width: '100%' }} className="flex items-center justify-center">
            {users === null ? (
              <img src={notFound} alt="not found" className="w-72" />
            ) : (
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
              />
            )}
          </Box>
        )}
      </Container>
    </>
  );
};

export default UserPage;
