import axios from 'axios';
import { API_URL } from '../api';

const initialSurveyorPayment = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/payments/surveyor/initial/${data.reference}/${data.surveyorId}/${data.adminId}`,
    { docId: data.docId },
    config
  );

  return response.data;
};

const finalSurveyorPayment = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/payments/surveyor/final/${data.reference}/${data.surveyorId}/${data.adminId}`,
    { docId: data.docId },
    config
  );

  return response.data;
};

const initialAgentPayment = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/payments/agent/initial/${data.reference}/${data.agentId}/${data.adminId}`,
    { docId: data.docId },
    config
  );

  return response.data;
};

const finalAgentPayment = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/payments/agent/final/${data.reference}/${data.agentId}/${data.adminId}`,
    { docId: data.docId },
    config
  );

  return response.data;
};

const getLYLTransfers = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}/payments/lyl-transfers/${data.adminId}`, config);

  if (response.data) {
    localStorage.setItem('lylTransfers', JSON.stringify(response.data));
  }

  return response.data;
};

const paymentService = {
  initialSurveyorPayment,
  finalSurveyorPayment,
  initialAgentPayment,
  finalAgentPayment,
  getLYLTransfers,
};
export default paymentService;
