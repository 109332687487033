import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunkWithHandler } from '../api';
import titlePerfectionService from './titlePerfectionService';

const initialState = {
  titlePerfections: [],
  singleTitlePerfection: {},
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
};

export const getAllTitlePerfectionPrice = createAsyncThunkWithHandler(
  'titlePerfection/getAllTitlePerfectionPrice',
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().adminAuth.user.token;
    const adminId = thunkAPI.getState().adminAuth.user.id;
    const response = await titlePerfectionService.getAllTitlePerfectionPrice(token, adminId);
    return response;
  }
);

export const getTitlePerfectionPrice = createAsyncThunkWithHandler(
  'titlePerfection/getTitlePerfectionPrice',
  async (state, thunkAPI) => {
    const token = thunkAPI.getState().adminAuth.user.token;
    const adminId = thunkAPI.getState().adminAuth.user.id;
    const response = await titlePerfectionService.getTitlePerfectionPrice(token, { state, adminId });
    return response;
  }
);

export const editTitlePerfectionPrice = createAsyncThunkWithHandler(
  'titlePerfection/editTitlePerfectionPrice',
  async (titlePerfectionData, thunkAPI) => {
    const token = thunkAPI.getState().adminAuth.user.token;
    const adminId = thunkAPI.getState().adminAuth.user.id;
    const response = await titlePerfectionService.editTitlePerfectionPrice(token, { ...titlePerfectionData, adminId });
    return response;
  }
);

export const addTitlePerfectionPrice = createAsyncThunkWithHandler(
  'titlePerfection/addTitlePerfectionPrice',
  async (titlePerfectionData, thunkAPI) => {
    const token = thunkAPI.getState().adminAuth.user.token;
    const adminId = thunkAPI.getState().adminAuth.user.id;
    const response = await titlePerfectionService.addTitlePerfectionPrice(token, { ...titlePerfectionData, adminId });
    return response;
  }
);

const titlePerfectionSlice = createSlice({
  name: 'titlePerfection',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTitlePerfectionPrice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllTitlePerfectionPrice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.titlePerfections = action.payload;
      })
      .addCase(getAllTitlePerfectionPrice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(getTitlePerfectionPrice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTitlePerfectionPrice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.singleTitlePerfection = action.payload;
      })
      .addCase(getTitlePerfectionPrice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(editTitlePerfectionPrice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editTitlePerfectionPrice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = 'Title perfection successfully edited';
      })
      .addCase(editTitlePerfectionPrice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(addTitlePerfectionPrice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addTitlePerfectionPrice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = 'Title perfection successfully added';
      })
      .addCase(addTitlePerfectionPrice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      });
  },
});

export const { reset } = titlePerfectionSlice.actions;
export default titlePerfectionSlice.reducer;
