import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunkWithHandler } from '../api';
import userService from './userService';

const usersFromAdmin = JSON.parse(localStorage.getItem('usersFromAdmin'));

const initialState = {
  user: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
};

if (usersFromAdmin) {
  initialState.user = usersFromAdmin;
}

export const getUser = createAsyncThunkWithHandler('user/getUser', async (userId, thunkAPI) => {
  const token = thunkAPI.getState().adminAuth.user.token;
  const id = thunkAPI.getState().adminAuth.user.id;
  const data = await userService.getUser(token, id, userId);

  return data;
});

export const updateUserStatus = createAsyncThunkWithHandler('user/updateUserStatus', async (userId, thunkAPI) => {
  const token = thunkAPI.getState().adminAuth.user.token;
  const id = thunkAPI.getState().adminAuth.user.id;
  const data = await userService.updateUserStatus(token, id, userId);

  return data;
});

export const unVerifyUser = createAsyncThunkWithHandler('user/unVerifyUser', async (userId, thunkAPI) => {
  const token = thunkAPI.getState().adminAuth.user.token;
  const id = thunkAPI.getState().adminAuth.user.id;
  const data = await userService.UnverifyUserStatus(token, id, userId);

  return data;
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.user = action.payload;
        state.isSuccess = true;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        state.isSuccess = false;
      })
      .addCase(updateUserStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.user = action.payload;
        state.isSuccess = true;
        state.message = 'This User has now been Verified';
      })
      .addCase(updateUserStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        state.isSuccess = false;
      })
      .addCase(unVerifyUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(unVerifyUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.user = action.payload;
        state.isSuccess = true;
        state.message = 'This User has now been Unverified';
      })
      .addCase(unVerifyUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        state.isSuccess = false;
      });
  },
});

export const { reset } = userSlice.actions;
export default userSlice.reducer;
