import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunkWithHandler } from '../api';
import surveyorService from './surveyorService';

const initialState = {
  surveyor: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
};

export const getSurveyor = createAsyncThunkWithHandler('surveyor/getSurveyor', async (surveyorId, thunkAPI) => {
  const token = thunkAPI.getState().adminAuth.user.token;
  const id = thunkAPI.getState().adminAuth.user.id;
  const data = await surveyorService.getSurveyor(token, id, surveyorId);
  return data;
});

export const updateSurveyorStatus = createAsyncThunkWithHandler(
  'surveyor/updateSurveyorStatus',
  async (surveyorId, thunkAPI) => {
    const token = thunkAPI.getState().adminAuth.user.token;
    const id = thunkAPI.getState().adminAuth.user.id;
    const data = await surveyorService.updateSurveyorStatus(token, id, surveyorId);

    return data;
  }
);

export const unVerifySurveyor = createAsyncThunkWithHandler(
  'surveyor/unVerifySurveyor',
  async (surveyorId, thunkAPI) => {
    const token = thunkAPI.getState().adminAuth.user.token;
    const id = thunkAPI.getState().adminAuth.user.id;
    const data = await surveyorService.UnverifySurveyorStatus(token, id, surveyorId);

    return data;
  }
);

const surveyorSlice = createSlice({
  name: 'surveyor',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSurveyor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSurveyor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.surveyor = action.payload;
        state.isSuccess = true;
      })
      .addCase(getSurveyor.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.surveyor = null;
        state.isSuccess = false;
      })
      .addCase(updateSurveyorStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSurveyorStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.surveyor = action.payload;
        state.isSuccess = true;
        state.message = 'This Surveyor has now been Verified';
      })
      .addCase(updateSurveyorStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.surveyor = null;
        state.isSuccess = false;
      })
      .addCase(unVerifySurveyor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(unVerifySurveyor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.surveyor = action.payload;
        state.isSuccess = true;
        state.message = 'This Surveyor has now been Unverified';
      })
      .addCase(unVerifySurveyor.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.surveyor = null;
        state.isSuccess = false;
      });
  },
});

export const { reset } = surveyorSlice.actions;
export default surveyorSlice.reducer;
