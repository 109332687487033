import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  FormHelperText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { countries, nigerianStates } from '../utils/data';
import { addTitlePerfectionPrice, reset } from '../features/titlePerfection/titlePerfectionSlice';

const AddTitlePerfection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, isSuccess, message, isError } = useSelector((state) => state.titlePerfection);
  const formik = useFormik({
    initialValues: {
      State: '',
      Country: '',
      DirectAssessment: '',
      StampsDuty: '',
      AdminCharge: '',
      DevelopmentLevy: '',
    },
    validationSchema: Yup.object({
      Country: Yup.string().required('Country is required'),
      State: Yup.string().required('State is required'),
      DirectAssessment: Yup.number().required('Direct Assessment is required').positive('Must be a positive number'),
      StampsDuty: Yup.number().required('Stamps Duty is required').positive('Must be a positive number'),
      AdminCharge: Yup.number().required('Admin Charge is required').positive('Must be a positive number'),
      DevelopmentLevy: Yup.number().required('Development Levy is required').positive('Must be a positive number'),
    }),
    onSubmit: (values) => {
      dispatch(addTitlePerfectionPrice(values));
    },
  });

  useEffect(() => {
    if (isSuccess && message === 'Title perfection successfully added') {
      toast.success(message, {
        onClose: () => {
          navigate('/dashboard/titlePerfection');
          dispatch(reset());
        },
      });
    }

    if (isError) {
      toast.error(message);
    }
  }, [isLoading, isError, isSuccess, message]);

  const numericFields = [
    { id: 'DirectAssessment', label: 'Direct Assessment' },
    { id: 'StampsDuty', label: 'Stamps Duty' },
    { id: 'AdminCharge', label: 'Admin Charge' },
    { id: 'DevelopmentLevy', label: 'Development Levy' },
  ];

  return (
    <Card sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
      <CardContent>
        <Typography variant="h5" component="h1" gutterBottom>
          Add Title Perfection
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {/* Country Selection */}
            <FormControl fullWidth error={formik.touched.Country && Boolean(formik.errors.Country)}>
              <InputLabel id="country-label">Country</InputLabel>
              <Select
                labelId="country-label"
                id="Country"
                name="Country"
                value={formik.values.Country}
                label="Country"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem value="">
                  <em>Select a country</em>
                </MenuItem>
                {countries.map((country, index) => (
                  <MenuItem key={index} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.Country && formik.errors.Country && (
                <FormHelperText>{formik.errors.Country}</FormHelperText>
              )}
            </FormControl>

            {/* State Input */}
            <FormControl fullWidth error={formik.touched.State && Boolean(formik.errors.State)}>
              {formik.values.Country === 'Nigeria' ? (
                <>
                  <InputLabel id="state-label">State</InputLabel>
                  <Select
                    labelId="state-label"
                    id="State"
                    name="State"
                    value={formik.values.State}
                    label="State"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value="">
                      <em>Select a state</em>
                    </MenuItem>
                    {nigerianStates.map((state, index) => (
                      <MenuItem key={index} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              ) : (
                <TextField
                  fullWidth
                  id="State"
                  name="State"
                  label="State"
                  value={formik.values.State}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.State && Boolean(formik.errors.State)}
                  helperText={formik.touched.State && formik.errors.State}
                />
              )}
            </FormControl>

            {/* Numeric Fields */}
            {numericFields.map(({ id, label }) => (
              <TextField
                key={id}
                fullWidth
                id={id}
                name={id}
                label={label}
                type="number"
                value={formik.values[id]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[id] && Boolean(formik.errors[id])}
                helperText={formik.touched[id] && formik.errors[id]}
              />
            ))}

            <Button
              type="submit"
              variant="contained"
              disableElevation
              className="bg-blue-800 w-full"
              color="primary"
              size="medium"
              fullWidth
              disabled={isLoading}
              sx={{ mt: 2 }}
            >
              {isLoading ? 'Please wait...' : 'Submit'}
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default AddTitlePerfection;
