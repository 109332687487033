import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import surveyorServices from './surveyorsService';
import { logoutError } from '../auth/authService';
import { createAsyncThunkWithHandler } from '../api';

const surveyors = JSON.parse(localStorage.getItem('surveyors'));

const initialState = {
  surveyors: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

if (surveyors) {
  initialState.surveyors = surveyors;
}

export const fetchSurveyors = createAsyncThunkWithHandler('surveyors/fetchSurveyors', async (_, thunkAPI) => {
  const token = thunkAPI.getState().adminAuth.user.token;
  const id = thunkAPI.getState().adminAuth.user.id;
  const response = await surveyorServices.fetchSurveyors(token, id);
  return response;
});

export const fetchUnverifiedSurveyors = createAsyncThunkWithHandler(
  'surveyors/fetchUnverifiedSurveyors',
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().adminAuth.user.token;
    const id = thunkAPI.getState().adminAuth.user.id;
    const response = await surveyorServices.fetchUnverifiedSurveyors(token, id);
    return response;
  }
);

const surveyorsSlice = createSlice({
  name: 'surveyors',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSurveyors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSurveyors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.surveyors = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchSurveyors.rejected, (state, action) => {
        state.isLoading = false;
        state.surveyors = [];
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchUnverifiedSurveyors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUnverifiedSurveyors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.surveyors = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchUnverifiedSurveyors.rejected, (state, action) => {
        state.isLoading = false;
        state.surveyors = [];
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      });
  },
});

export const { reset } = surveyorsSlice.actions;
export default surveyorsSlice.reducer;
