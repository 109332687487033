import { Box, Button, Chip, Container, Stack, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { Add } from '@mui/icons-material';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllTitlePerfectionPrice } from '../features/titlePerfection/titlePerfectionSlice';
import Loader from '../components/loader';
import { reset } from '../features/propertydocuments/PropertydocumentSlice';

const TitlePerfections = () => {
  const dispatch = useDispatch();
  const viewImg = '/assets/icons/search.png';
  const notFound = '/assets/images/covers/notfound.svg';
  const { titlePerfections, isLoading, isError, message } = useSelector((state) => state.titlePerfection);

  useEffect(() => {
    dispatch(getAllTitlePerfectionPrice());
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          reset();
        },
      });
    }
  }, [isError, message]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 40 },
    {
      field: 'Country',
      headerName: 'Country',
      width: 100,
    },
    {
      field: 'State',
      headerName: 'State',
      width: 100,
    },

    {
      field: 'DirectAssessment',
      headerName: 'Direct Assessment',
      width: 150,
    },
    {
      field: 'StampsDuty',
      headerName: 'Stamps Duty',
      width: 150,
    },
    {
      field: 'AdminCharge',
      headerName: 'Admin Charge',
      width: 150,
    },
    {
      field: 'DevelopmentLevy',
      headerName: 'Development Levy',
      width: 150,
    },

    {
      field: 'Action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/dashboard/titlePerfection/edit?state=${params.row.State}`}>
              <Chip
                variant="contained"
                color="info"
                label="View"
                icon={<img src={viewImg} alt="verified_img" className="h-4 w-4" />}
                size="small"
                sx={{ cursor: 'pointer' }}
              />
            </Link>
          </>
        );
      },
    },
  ];

  const mappedArray = titlePerfections.map((item, index) => {
    const { _id, ...rest } = item;
    return {
      id: index + 1,
      ...rest,
    };
  });

  return (
    <>
      <Helmet>
        <title> Documents | Lock.your.Land Dashboard </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Title Perfection
          </Typography>
          <Link to="/dashboard/titlePerfection/add">
            <Button className="flex items-center space-x-2">
              <Add /> <span>Add Title Perfection</span>
            </Button>
          </Link>
        </Stack>
        {isLoading ? (
          <Box sx={{ width: '100%' }} className="flex items-center justify-center">
            <Loader />
          </Box>
        ) : (
          <Box sx={{ width: '100%' }} className="flex items-center justify-center">
            {titlePerfections?.length < 1 ? (
              <img src={notFound} alt="not found" className="w-72" />
            ) : (
              <DataGrid
                rows={mappedArray}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                checkboxSelection
                disableRowSelectionOnClick
              />
            )}
          </Box>
        )}
      </Container>
    </>
  );
};

export default TitlePerfections;
