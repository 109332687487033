import axios from 'axios';
import { API_URL } from '../api';

const fetchUnverifiedDocsLand = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/documents/unverified_land/${id}`, config);

  if (response.data) {
    localStorage.setItem('docs', JSON.stringify(response.data));
  }
  return response.data;
};

const fetchUnverifiedSurveyLand = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/documents/unverified_land_survey/${id}`, config);

  if (response.data) {
    localStorage.setItem('docs', JSON.stringify(response.data));
  }
  return response.data;
};

const VerifiedDocsLand = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/documents/verified_land/${id}`, config);

  if (response.data) {
    localStorage.setItem('docs', JSON.stringify(response.data));
  }
  return response.data;
};

const VerifiedSurveyLand = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/documents/verified_land_survey/${id}`, config);

  if (response.data) {
    localStorage.setItem('docs', JSON.stringify(response.data));
  }
  return response.data;
};

const fetchPendingDocsLand = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/documents/pending_land/${id}`, config);

  if (response.data) {
    localStorage.setItem('docs', JSON.stringify(response.data));
  }
  return response.data;
};

const docServices = {
  fetchUnverifiedDocsLand,
  fetchPendingDocsLand,
  fetchUnverifiedSurveyLand,
  VerifiedDocsLand,
  VerifiedSurveyLand,
};

export default docServices;
