import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import docServices from './PropertydocumentService';
import { logoutError } from '../auth/authService';
import { createAsyncThunkWithHandler } from '../api';

const docs = JSON.parse(localStorage.getItem('docs'));

const initialState = {
  docs: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

export const fetchUnverifiedDocsProperty = createAsyncThunkWithHandler(
  'documents/fetchUnverifiedDocsProperty',
  async (_, thunkAPI) => {
    const { token, id } = thunkAPI.getState().adminAuth.user;

    const response = await docServices.fetchUnverifiedDocsProperty(token, id);
    return response;
  }
);

export const fetchUnverifiedSurveyProperty = createAsyncThunkWithHandler(
  'documents/fetchUnverifiedSurveyProperty',
  async (_, thunkAPI) => {
    const { token, id } = thunkAPI.getState().adminAuth.user;
    const response = await docServices.fetchUnverifiedSurveyProperty(token, id);
    return response;
  }
);

export const VerifiedSurveyProperty = createAsyncThunkWithHandler(
  'documents/VerifiedSurveyProperty',
  async (_, thunkAPI) => {
    const { token, id } = thunkAPI.getState().adminAuth.user;
    const response = await docServices.VerifiedSurveyProperty(token, id);
    return response;
  }
);

export const fetchPendingDocsProperty = createAsyncThunkWithHandler(
  'documents/fetchPendingDocsProperty',
  async (_, thunkAPI) => {
    const { token, id } = thunkAPI.getState().adminAuth.user;
    const response = await docServices.fetchPendingDocsProperty(token, id);
    return response;
  }
);

export const fetchVerifiedDocsProperty = createAsyncThunkWithHandler(
  'documents/fetchVerifiedDocsProperty',
  async (_, thunkAPI) => {
    const { token, id } = thunkAPI.getState().adminAuth.user;
    const response = await docServices.VerifiedDocsProperty(token, id);
    return response;
  }
);

export const FetchVerifiedSurveyProperty = createAsyncThunkWithHandler(
  'documents/FetchVerifiedSurveyProperty',
  async (_, thunkAPI) => {
    const { token, id } = thunkAPI.getState().adminAuth.user;
    const response = await docServices.VerifiedSurveyProperty(token, id);
    return response;
  }
);

const PropertydocumentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnverifiedDocsProperty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUnverifiedDocsProperty.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchUnverifiedDocsProperty.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = [];
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchUnverifiedSurveyProperty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUnverifiedSurveyProperty.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchUnverifiedSurveyProperty.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = [];
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(FetchVerifiedSurveyProperty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(FetchVerifiedSurveyProperty.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(FetchVerifiedSurveyProperty.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = [];
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchPendingDocsProperty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPendingDocsProperty.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchPendingDocsProperty.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = [];
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchVerifiedDocsProperty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchVerifiedDocsProperty.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchVerifiedDocsProperty.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = [];
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      });
  },
});

export const { reset } = PropertydocumentsSlice.actions;
export default PropertydocumentsSlice.reducer;
