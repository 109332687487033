import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunkWithHandler } from '../api';
import agentServices from './agentsService';

const initialState = {
  agents: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

export const fetchAgents = createAsyncThunkWithHandler('agents/fetchAgents', async (_, thunkAPI) => {
  const token = thunkAPI.getState().adminAuth.user.token;
  const id = thunkAPI.getState().adminAuth.user.id;
  const response = await agentServices.fetchAgents(token, id);
  return response;
});

export const fetchUnverifiedAgents = createAsyncThunkWithHandler(
  'agents/fetchUnverifiedAgents',
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().adminAuth.user.token;
    const id = thunkAPI.getState().adminAuth.user.id;
    const response = await agentServices.fetchUnverifiedAgents(token, id);
    return response;
  }
);

const agentsSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAgents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.agents = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchAgents.rejected, (state, action) => {
        state.isLoading = false;
        state.agents = [];
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchUnverifiedAgents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUnverifiedAgents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.agents = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchUnverifiedAgents.rejected, (state, action) => {
        state.isLoading = false;
        state.agents = [];
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      });
  },
});

export const { reset } = agentsSlice.actions;
export default agentsSlice.reducer;
