import * as yup from 'yup';

// export const url = 'http://localhost:5000';
export const url = 'https://lockyourlands.uw.r.appspot.com';

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,}$/;

export const loginSchema = yup.object().shape({
  Email: yup.string().email('Please enter a valid email').required('Email is Required'),

  Password: yup
    .string()
    .min(7)
    .matches(passwordRules, {
      message: 'Please create a stronger password(1 upper case letter, 1 lower case letter, 1 numeric digit)',
    })
    .required('Password is required'),
});
