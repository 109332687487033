import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import usePaystackTransfer from '../../hooks/usePaystackTransfer';
import usePaystackTransferSliceAction from '../../hooks/usePaystackTransferSliceAction';

const Paystack = ({ publicKey, open, handleClose, onTransferInitiated, fullname, amount }) => {
  const {
    accountNumber,
    setAccountNumber,
    bankCode,
    setBankCode,
    error,
    banks,
    Loading,
    showOtpInput,
    otp,
    setOtp,
    handleTransfer,
    handleOtpSubmit,
    testAccountInfo,
  } = usePaystackTransfer({ publicKey, onTransferInitiated, amount, fullname });
  const { isLoading } = usePaystackTransferSliceAction({ handleClose });
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="salary-transfer-modal">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        {!showOtpInput ? (
          <>
            <Typography variant="h6" component="h2" gutterBottom>
              Initiate Salary Transfer
              <Tooltip title={testAccountInfo}>
                <IconButton size="small" sx={{ ml: 1 }}>
                  <InfoIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Typography>
            <Box component="form" onSubmit={handleTransfer}>
              <TextField
                fullWidth
                margin="normal"
                label="Staff Name"
                value={fullname}
                InputProps={{
                  readOnly: true,
                }}
              />

              <TextField
                fullWidth
                margin="normal"
                label="Account Number"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
                required
              />
              <TextField
                fullWidth
                margin="normal"
                select
                label="Bank"
                value={bankCode}
                onChange={(e) => setBankCode(e.target.value)}
                required
              >
                {banks.map((bank) => (
                  <MenuItem key={bank.code} value={bank.code}>
                    {bank.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                fullWidth
                margin="normal"
                label="Amount (NGN)"
                type="number"
                value={amount}
                InputProps={{
                  readOnly: true,
                }}
              />
              {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              )}
              <Button
                type="submit"
                variant="contained"
                disableElevation
                className="bg-blue-800 w-full"
                color="primary"
                size="medium"
                sx={{ mt: 3 }}
                disabled={Loading}
              >
                {Loading ? <CircularProgress size={24} /> : 'Initiate Transfer'}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h6" component="h2" gutterBottom>
              Enter OTP
            </Typography>
            <Box component="form" onSubmit={handleOtpSubmit}>
              <TextField
                fullWidth
                margin="normal"
                label="OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
              {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              )}

              <Button
                type="submit"
                variant="contained"
                disableElevation
                className="bg-blue-800 w-full"
                color="primary"
                size="medium"
                sx={{ mt: 3 }}
                disabled={Loading}
              >
                {Loading || isLoading ? <CircularProgress size={24} /> : 'Verify OTP'}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default Paystack;
