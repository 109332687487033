import axios from 'axios';
import { API_URL } from '../api';

const getSurveyor = async (token, id, surveyorId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}/surveyors/surveyor/${surveyorId}/${id}`, config);

  if (response.data) {
    localStorage.setItem('surveyorsFromAdmin', JSON.stringify(response.data));
  }

  return response.data;
};

const updateSurveyorStatus = async (token, id, surveyorId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(`${API_URL}/surveyors/surveyor/verify/${surveyorId}/${id}`, '', config);

  if (response.data) {
    localStorage.setItem('surveyorsFromAdmin', JSON.stringify(response.data));
  }

  return response.data;
};

const UnverifySurveyorStatus = async (token, id, surveyorId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(`${API_URL}/surveyors/surveyor/unverify/${surveyorId}/${id}`, '', config);

  if (response.data) {
    localStorage.setItem('surveyorsFromAdmin', JSON.stringify(response.data));
  }

  return response.data;
};
const surveyorService = { getSurveyor, updateSurveyorStatus, UnverifySurveyorStatus };
export default surveyorService;
