import axios from 'axios';
import { API_URL } from '../api';

const fetchUnverifiedDocsProperty = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/documents/unverified_property/${id}`, config);

  if (response.data) {
    localStorage.setItem('docs', JSON.stringify(response.data));
  }
  return response.data;
};

const fetchUnverifiedSurveyProperty = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/documents/unverified_property_survey/${id}`, config);

  if (response.data) {
    localStorage.setItem('docs', JSON.stringify(response.data));
  }
  return response.data;
};

const VerifiedDocsProperty = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/documents/verified_property/${id}`, config);

  if (response.data) {
    localStorage.setItem('docs', JSON.stringify(response.data));
  }
  return response.data;
};

const VerifiedSurveyProperty = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/documents/verified_property_survey/${id}`, config);

  if (response.data) {
    localStorage.setItem('docs', JSON.stringify(response.data));
  }
  return response.data;
};

const fetchPendingDocsProperty = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/documents/pending_property/${id}`, config);

  if (response.data) {
    localStorage.setItem('docs', JSON.stringify(response.data));
  }
  return response.data;
};

const docServices = {
  fetchUnverifiedDocsProperty,
  fetchPendingDocsProperty,
  fetchUnverifiedSurveyProperty,
  VerifiedDocsProperty,
  VerifiedSurveyProperty,
};

export default docServices;
