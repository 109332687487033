import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunkWithHandler } from '../api';
import paymentService from './paymentService';

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
};

export const initialSurveyorPayment = createAsyncThunkWithHandler(
  'payment/initialSurveyorPayment',
  async (paymentData, thunkAPI) => {
    const token = thunkAPI.getState().adminAuth.user.token;
    const data = await paymentService.initialSurveyorPayment(token, paymentData);

    return data;
  }
);

export const finalSurveyorPayment = createAsyncThunkWithHandler(
  'payment/finalSurveyorPayment',
  async (paymentData, thunkAPI) => {
    const token = thunkAPI.getState().adminAuth.user.token;
    const data = await paymentService.finalSurveyorPayment(token, paymentData);
    return data;
  }
);

export const initialAgentPayment = createAsyncThunkWithHandler(
  'payment/initialAgentPayment',
  async (paymentData, thunkAPI) => {
    const token = thunkAPI.getState().adminAuth.user.token;
    const data = await paymentService.initialAgentPayment(token, paymentData);

    return data;
  }
);

export const finalAgentPayment = createAsyncThunkWithHandler(
  'payment/finalAgentPayment',
  async (paymentData, thunkAPI) => {
    const token = thunkAPI.getState().adminAuth.user.token;
    const data = await paymentService.finalAgentPayment(token, paymentData);
    return data;
  }
);

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initialSurveyorPayment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(initialSurveyorPayment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = 'Initial Payment Successfull';
      })
      .addCase(initialSurveyorPayment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(finalSurveyorPayment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(finalSurveyorPayment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = 'Final Payment Successfull';
      })
      .addCase(finalSurveyorPayment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(initialAgentPayment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(initialAgentPayment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = 'Initial Payment Successfull';
      })
      .addCase(initialAgentPayment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      })
      .addCase(finalAgentPayment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(finalAgentPayment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = 'Final Payment Successfull';
      })
      .addCase(finalAgentPayment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isSuccess = false;
      });
  },
});

export const { reset } = paymentSlice.actions;
export default paymentSlice.reducer;
