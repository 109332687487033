import React, { useEffect } from 'react';
import { Box, Chip, Container, Stack, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/loader';
import { fetchUnverifiedSurveyors, reset } from '../features/surveyors/surveyorsSlice';
import { fetchUnverifiedAgents } from '../features/agents/agentsSlice';

const UnverifiedAgents = () => {
  const dispatch = useDispatch();
  const pendingImg = '/assets/icons/pending.png';
  const unverifiedImg = '/assets/icons/unverified.png';
  const viewImg = '/assets/icons/search.png';
  const notFound = '/assets/images/covers/notfound.svg';
  const { surveyors, isLoading, isError, message } = useSelector((state) => state.surveyors);
  const { agents } = useSelector((state) => state.agents);

  useEffect(() => {
    dispatch(fetchUnverifiedSurveyors());
    dispatch(fetchUnverifiedAgents());
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          reset();
        },
      });
    }
  }, [isError, message]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 20 },
    {
      field: 'Firstname',
      headerName: 'Firstname',
      width: 100,
    },
    {
      field: 'Lastname',
      headerName: 'Lastname',
      width: 100,
    },
    {
      field: 'Email',
      headerName: 'Email',
      width: 170,
    },
    {
      field: 'Category',
      headerName: 'Category',
      width: 160,
    },
    {
      field: 'License',
      headerName: 'License',
      width: 140,
    },

    {
      field: 'Status',
      headerName: 'Status',
      width: 130,
      renderCell: (params) => {
        return (
          <>
            <Chip
              variant={`${params.row.Status === 'No' ? 'outlined' : 'contained'}`}
              color={`${params.row.Status === 'No' ? 'warning' : 'error'}`}
              label={`${params.row.Status === 'No' ? 'Pending' : 'Failed'}`}
              icon={
                <img
                  src={params.row.Status === 'No' ? pendingImg : unverifiedImg}
                  alt="verified_img"
                  className="h-4 w-4"
                />
              }
              size="small"
              sx={{ cursor: 'pointer' }}
            />
          </>
        );
      },
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={
                params.row.Category === 'Agent'
                  ? `/dashboard/agents/agent/${params.row.agentId}`
                  : `/dashboard/surveyors/surveyor/${params.row.agentId}`
              }
            >
              <Chip
                variant="contained"
                color="info"
                label="View"
                icon={<img src={viewImg} alt="verified_img" className="h-4 w-4" />}
                size="small"
                sx={{ cursor: 'pointer' }}
              />
            </Link>
          </>
        );
      },
    },
  ];

  const rows = [];

  const combinedAgents = [...surveyors, ...agents];

  combinedAgents?.map((agent, index) => {
    return rows.push({
      agentId: agent._id,
      id: index + 1,
      Firstname: agent.Firstname,
      Lastname: agent.Lastname,
      Email: agent.Email,
      Status: agent.Verified,
      License: agent.License || 'Not Assigned',
      Category: agent.AgentType === 'Agent' ? 'Agent' : 'Surveyor',
    });
  });
  console.log(rows);
  return (
    <>
      <Helmet>
        <title> Documents | Lock.your.Land Dashboard </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Unverified Agents/Surveryors
          </Typography>
        </Stack>
        {isLoading ? (
          <Box sx={{ height: 400, width: '100%' }} className="flex items-center justify-center">
            <Loader />
          </Box>
        ) : (
          <Box sx={{ height: 400, width: '100%' }} className="flex items-center justify-center">
            {agents?.length < 1 && surveyors?.length < 1 ? (
              <img src={notFound} alt="not found" className="w-72" />
            ) : (
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
              />
            )}
          </Box>
        )}
      </Container>
    </>
  );
};

export default UnverifiedAgents;
