import React, { useEffect } from 'react';
import { Box, Button, Chip, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSurveyor, reset, updateSurveyorStatus, unVerifySurveyor } from '../features/surveyor/surveyorSlice';
import Loader from '../components/loader/Loader';

const SurveyorDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const verifiedImg = '/assets/icons/verified.png';
  const unverifiedImg = '/assets/icons/unverified.png';
  const pendingImg = '/assets/icons/pending.png';
  const { surveyor, isLoading, isError, isSuccess, message } = useSelector((state) => state.surveyor);
  const { surveyorId } = useParams();

  useEffect(() => {
    dispatch(getSurveyor(surveyorId));
    reset();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(reset());
    }, 3000);
  }, []);

  const HandleVerify = () => {
    Swal.fire({
      title: 'Do you want to Verify this user?',
      showCancelButton: true,
      confirmButtonText: 'Verify',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(updateSurveyorStatus(surveyorId));
      }
    });
  };

  const HandleUnverify = () => {
    Swal.fire({
      title: 'Do you want to Unverify this user?',
      showCancelButton: true,
      confirmButtonText: 'Unverify',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(unVerifySurveyor(surveyorId));
      }
    });
  };

  useEffect(() => {
    if (isError) {
      toast.error(message, {
        onClose: () => {
          window.location.reload(false);
          dispatch(reset());
        },
      });
    }

    if (isSuccess && message.includes('This Surveyor has now been Verified')) {
      navigate('/dashboard/verifiedAgents');
      dispatch(reset());
    }
    if (isSuccess && message.includes('This Surveyor has now been Unverified')) {
      navigate('/dashboard/unVerifiedAgents');
      dispatch(reset());
    }
  }, [isError, isSuccess, message, surveyor]);

  return (
    <>
      <Helmet>
        <title> Document | Lock.your.Land Dashboard </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Surveyor's Details
          </Typography>
        </Stack>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            {surveyor && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <Box className="">
                    <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                      <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                        Owners Information: <br />
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                          <p className="text-blue-900">
                            Fullname: <br /> <span className="font-bold">{surveyor?.surveyor?.Firstname}</span>
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <p className="text-blue-900">
                            Lastname: <br /> <span className="font-bold">{surveyor?.surveyor?.Lastname}</span>
                          </p>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                          <p className="text-blue-900">
                            Phone: <br /> <span className="font-bold">{surveyor?.surveyor?.Phone}</span>
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <p className="text-blue-900">
                            Email: <br /> <span className="font-bold">{surveyor?.surveyor?.Email}</span>
                          </p>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                          <p className="text-blue-900">
                            Address: <br /> <span className="font-bold">{surveyor?.surveyor?.Address}</span>
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <p className="text-blue-900">
                            NIN: <br /> <span className="font-bold">{surveyor?.surveyor?.NIN}</span>
                          </p>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <Box className="">
                    <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                      <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                        License Information: <br />
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                          <p className="text-blue-900">
                            License Number: <br /> <span className="font-bold">{surveyor?.surveyor?.License}</span>
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <p className="text-blue-900">
                            License year: <br />{' '}
                            <span className="font-bold">
                              {surveyor?.surveyor?.LicenseYear
                                ? new Date(surveyor?.surveyor?.LicenseYear).toLocaleString('en-US', {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric',
                                  })
                                : null}
                            </span>
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <p className="text-blue-900">License Validity</p>
                          <Chip
                            label={surveyor?.surveyor?.VerifiedLicense === true ? 'Valid' : 'Failed'}
                            size="small"
                            color={surveyor?.surveyor?.VerifiedLicense === true ? 'success' : 'error'}
                            icon={
                              <img
                                src={surveyor?.surveyor?.VerifiedLicense === true ? verifiedImg : unverifiedImg}
                                alt="verified img"
                                className="w-4 h-4"
                              />
                            }
                          />
                        </Grid>
                        {surveyor?.licenseValidation && (
                          <>
                            <Grid item xs={12} sm={12} md={6}>
                              <p className="text-blue-900">
                                License Type: <br />{' '}
                                <span className="font-bold">{surveyor?.licenseValidation?.Licensetype} License</span>
                              </p>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                              <p className="text-blue-900">
                                License Name: <br /> {surveyor?.licenseValidation?.Title}
                                <span className="font-bold">
                                  {surveyor?.licenseValidation?.Lastname} {surveyor?.licenseValidation?.Firstname}{' '}
                                  {surveyor?.licenseValidation?.Middlename}
                                </span>
                              </p>
                            </Grid>
                          </>
                        )}

                        <Grid item xs={12} sm={12} md={6}>
                          <p className="text-blue-900">
                            Application Date: <br />{' '}
                            <span className="font-bold">
                              {surveyor?.surveyor?.createdAt
                                ? new Date(surveyor?.surveyor?.createdAt).toLocaleString('en-US', {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric',
                                  })
                                : null}
                            </span>
                          </p>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                          <p className="text-blue-900">Verification Status</p>
                          <Chip
                            label={
                              surveyor?.surveyor?.Verified === 'Yes' && surveyor?.surveyor?.VerifiedLicense === true
                                ? 'Verified'
                                : surveyor?.surveyor?.Verified === 'No' && surveyor?.surveyor?.VerifiedLicense === true
                                ? 'Pending'
                                : 'Failed'
                            }
                            size="small"
                            color={
                              surveyor?.surveyor?.Verified === 'Yes' && surveyor?.surveyor?.VerifiedLicense === true
                                ? 'success'
                                : surveyor?.surveyor?.Verified === 'No' && surveyor?.surveyor?.VerifiedLicense === true
                                ? 'warning'
                                : 'error'
                            }
                            icon={
                              <img
                                src={
                                  surveyor?.surveyor?.Verified === 'Yes' && surveyor?.surveyor?.VerifiedLicense === true
                                    ? verifiedImg
                                    : surveyor?.surveyor?.Verified === 'No' &&
                                      surveyor?.surveyor?.VerifiedLicense === true
                                    ? pendingImg
                                    : unverifiedImg
                                }
                                alt="verified img"
                                className="w-4 h-4"
                              />
                            }
                          />
                        </Grid>

                        <Box className="mt-5 p-3">
                          {surveyor?.surveyor?.Verified !== 'Yes' ? (
                            <>
                              <Button
                                variant="outlined"
                                size="medium"
                                color="success"
                                sx={{
                                  textTransform: 'none',
                                  fontWeight: 'bold',
                                }}
                                onClick={HandleVerify}
                              >
                                {isLoading ? 'Please wait...' : 'Verify User'}
                              </Button>
                              <Typography sx={{ color: 'red', fontSize: '10px' }} variant="subtitle2">
                                (*) Check if the name on license aligns with the name provided by surveryor.
                              </Typography>
                            </>
                          ) : surveyor?.surveyor?.Verified === 'Yes' ? (
                            <Button
                              variant="outlined"
                              size="medium"
                              color="error"
                              sx={{
                                textTransform: 'none',
                                fontWeight: 'bold',
                              }}
                              onClick={HandleUnverify}
                            >
                              {isLoading ? 'Please wait...' : 'Unverify User'}
                            </Button>
                          ) : null}
                        </Box>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default SurveyorDetails;
