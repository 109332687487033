import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Iconify from '../components/iconify';
// sections
import { AppNewsUpdate, AppOrderTimeline, AppWidgetSummary } from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const {
    user: { loggedIn },
  } = useSelector((state) => state.adminAuth);
  const navigate = useNavigate();

  useEffect(() => {
    const currentTime = new Date();
    const timeDifferenceInMs = currentTime - loggedIn;
    const timeDifferenceInHours = timeDifferenceInMs / (1000 * 60 * 60);

    const logoutThreshold = 24; // 24 hours
    // Check if the future time is greater than the current time
    if (timeDifferenceInHours >= logoutThreshold) {
      localStorage.removeItem('Adminuser');
      navigate('/login');
    }
  }, []);

  const theme = useTheme();
  // const dispatch = useDispatch()
  // const {docs, isLoading} = useSelector((state) => state.documents)

  // useEffect(() => {

  // }, [])

  return (
    <>
      <Helmet>
        <title> Dashboard | Lock your Land </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Verified Users" total={4} icon={'iconoir:verified-user'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Unverified Users" total={6} color="error" icon={'mdi:user'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Landed properties" total={6} color="warning" icon={'mdi:land-plots-marker'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Built properties" total={7} color="secondary" icon={'teenyicons:building-solid'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Unverified Documents"
              total={3}
              color="info"
              icon={'fluent-mdl2:survey-questions'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Assigned Surveys" total={2} icon={'emojione-v1:document'} color="success" />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Issued Survey" total={1} color="error" icon={'flat-color-icons:survey'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Escrow Refund" total={1723315} color="info" icon={'mdi:money-100'} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
