import axios from 'axios';
import { API_URL } from '../api';

const getUser = async (token, id, userId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}/users/user/${userId}/${id}`, config);

  if (response.data) {
    localStorage.setItem('usersFromAdmin', JSON.stringify(response.data));
  }

  return response.data;
};

const updateUserStatus = async (token, id, userId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(`${API_URL}/users/user/verify/${userId}/${id}`, '', config);

  if (response.data) {
    localStorage.setItem('usersFromAdmin', JSON.stringify(response.data));
  }

  return response.data;
};

const UnverifyUserStatus = async (token, id, userId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(`${API_URL}/users/user/unverify/${userId}/${id}`, '', config);

  if (response.data) {
    localStorage.setItem('usersFromAdmin', JSON.stringify(response.data));
  }

  return response.data;
};
const userService = { getUser, updateUserStatus, UnverifyUserStatus };
export default userService;
