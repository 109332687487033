import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import documentService from './propertydocumentService';
import { logoutError } from '../auth/authService';
import { createAsyncThunkWithHandler } from '../api';

const documentsFromAdmin = JSON.parse(localStorage.getItem('documentsFromAdmin'));

const initialState = {
  document: null,
  documentReport: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
};

// if (documentsFromAdmin) {
//   initialState.document = documentsFromAdmin;
// }

export const getDocument = createAsyncThunkWithHandler('document/getDocument', async (documentId, thunkAPI) => {
  const token = thunkAPI.getState().adminAuth.user.token;
  const id = thunkAPI.getState().adminAuth.user.id;
  const data = await documentService.getDocument(token, id, documentId);
  return data;
});

export const getDocumentReport = createAsyncThunkWithHandler(
  'document/getDocumentReport',
  async (documentId, thunkAPI) => {
    const token = thunkAPI.getState().adminAuth.user.token;
    const id = thunkAPI.getState().adminAuth.user.id;
    const data = await documentService.getDocumentReport(token, id, documentId);
    return data;
  }
);

export const assignDocumentsToAgents = createAsyncThunkWithHandler(
  'document/assignDocumentsToAgents',
  async (value, thunkAPI) => {
    const { docId, agentsLicense, agentType } = value;
    const token = thunkAPI.getState().adminAuth.user.token;
    const id = thunkAPI.getState().adminAuth.user.id;
    const data = await documentService.assignDocumentsToAgents(token, id, docId, agentsLicense, agentType);
    return data;
  }
);

export const assignDocumentsToSurveyors = createAsyncThunkWithHandler(
  'document/assignDocumentsToSurveyors',
  async (value, thunkAPI) => {
    const { docId, surveyorsLicense, agentType } = value;
    const token = thunkAPI.getState().adminAuth.user.token;
    const id = thunkAPI.getState().adminAuth.user.id;
    const data = await documentService.assignDocumentsToSurveyors(token, id, docId, surveyorsLicense);

    return data;
  }
);

export const sendDocumentStatusToBuyer = createAsyncThunkWithHandler(
  'document/sendDocumentStatusToBuyer',
  async (value, thunkAPI) => {
    const { docId, agentsLicense } = value;
    const token = thunkAPI.getState().adminAuth.user.token;
    const id = thunkAPI.getState().adminAuth.user.id;
    const data = await documentService.sendDocumentStatusToBuyer(token, id, docId, agentsLicense);

    return data;
  }
);

export const sendSurveyStatusToBuyer = createAsyncThunkWithHandler(
  'document/sendSurveyStatusToBuyer',
  async (value, thunkAPI) => {
    const { docId, agentsLicense } = value;
    const token = thunkAPI.getState().adminAuth.user.token;
    const id = thunkAPI.getState().adminAuth.user.id;
    const data = await documentService.sendSurveyStatusToBuyer(token, id, docId, agentsLicense);

    return data;
  }
);

export const unVerifyDocument = createAsyncThunkWithHandler(
  'document/unVerifyDocument',
  async (documentId, thunkAPI) => {
    const token = thunkAPI.getState().adminAuth.user.token;
    const id = thunkAPI.getState().adminAuth.user.id;
    const data = await documentService.UnverifyDocumentStatus(token, id, documentId);

    return data;
  }
);

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDocument.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.document = action.payload;
        state.isSuccess = true;
      })
      .addCase(getDocument.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.document = null;
        state.isSuccess = false;
      })
      .addCase(getDocumentReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDocumentReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.document = action.payload;
        state.isSuccess = true;
      })
      .addCase(getDocumentReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.document = null;
        state.isSuccess = false;
      })
      .addCase(assignDocumentsToAgents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assignDocumentsToAgents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.document = action.payload;
        state.isSuccess = true;
        state.message = 'This Document has now been Assigned';
      })
      .addCase(assignDocumentsToAgents.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.document = null;
        state.isSuccess = false;
      })
      .addCase(assignDocumentsToSurveyors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assignDocumentsToSurveyors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.document = action.payload;
        state.isSuccess = true;
        state.message = 'This Document has now been Assigned';
      })
      .addCase(assignDocumentsToSurveyors.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.document = null;
        state.isSuccess = false;
      })
      .addCase(unVerifyDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(unVerifyDocument.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.document = action.payload;
        state.isSuccess = true;
        state.message = '';
      })
      .addCase(unVerifyDocument.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.document = null;
        state.isSuccess = false;
      })
      .addCase(sendDocumentStatusToBuyer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendDocumentStatusToBuyer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.document = action.payload;
        state.isSuccess = true;
        state.message = 'Message has been sent to Buyer';
      })
      .addCase(sendDocumentStatusToBuyer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.document = null;
        state.isSuccess = false;
      })
      .addCase(sendSurveyStatusToBuyer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendSurveyStatusToBuyer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.document = action.payload;
        state.isSuccess = true;
        state.message = 'Message has been sent to Buyer';
      })
      .addCase(sendSurveyStatusToBuyer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.document = null;
        state.isSuccess = false;
      });
  },
});

export const { reset } = documentSlice.actions;
export default documentSlice.reducer;
