import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userServices from './usersService';
import { logoutError } from '../auth/authService';
import { createAsyncThunkWithHandler } from '../api';

const users = JSON.parse(localStorage.getItem('lyl-users'));

const initialState = {
  users: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

if (users) {
  initialState.users = users;
}

export const fetchUsers = createAsyncThunkWithHandler('users/fetchUsers', async (_, thunkAPI) => {
  const token = thunkAPI.getState().adminAuth.user.token;
  const id = thunkAPI.getState().adminAuth.user.id;
  const response = await userServices.fetchUsers(token, id);
  return response;
});

export const fetchUnverifiedUsers = createAsyncThunkWithHandler('users/fetchUnverifiedUsers', async (_, thunkAPI) => {
  const token = thunkAPI.getState().adminAuth.user.token;
  const id = thunkAPI.getState().adminAuth.user.id;
  const response = await userServices.fetchUnverifiedUsers(token, id);
  return response;
});

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.users = [];
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchUnverifiedUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUnverifiedUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchUnverifiedUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.users = [];
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      });
  },
});

export const { reset } = usersSlice.actions;
export default usersSlice.reducer;
