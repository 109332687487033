import React, { useEffect } from 'react';
import { Box, Button, Chip, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, reset, unVerifyUser, updateUserStatus } from '../features/user/userSlice';

const User = () => {
  const dispatch = useDispatch();
  const verifiedImg = '/assets/icons/verified.png';
  const unverifiedImg = '/assets/icons/unverified.png';
  const pendingImg = '/assets/icons/pending.png';
  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.user);
  const { userId } = useParams();

  useEffect(() => {
    dispatch(getUser(userId));
    reset();
  }, []);

  const HandleVerify = () => {
    Swal.fire({
      title: 'Do you want to Verify this user?',
      showCancelButton: true,
      confirmButtonText: 'Verify',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(updateUserStatus(userId));
      }
    });
  };

  const HandleUnverify = () => {
    Swal.fire({
      title: 'Do you want to Unverify this user?',
      showCancelButton: true,
      confirmButtonText: 'Unverify',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(unVerifyUser(userId));
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(reset());
    }, 3000);
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (
      isSuccess &&
      (message.includes('This User has now been Verified') || message.includes('This User has now been Unverified'))
    ) {
      toast.info(message);
    }

    dispatch(reset());
  }, [isError, isSuccess, message, user]);

  return (
    <>
      <Helmet>
        <title> User | Lock.your.Land Dashboard </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            User Details
          </Typography>
        </Stack>

        {user && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6} xl={12}>
              <Box className="">
                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                    Contact Information: <br />
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <p className="text-blue-900">
                        Fullname: <br /> <span className="font-bold">{user?.Firstname}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <p className="text-blue-900">
                        Lastname: <br /> <span className="font-bold">{user?.Lastname}</span>
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <p className="text-blue-900">
                        Gender: <br /> <span className="font-bold">{user?.Gender}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <p className="text-blue-900">
                        Phone: <br /> <span className="font-bold">{user?.Phone}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <p className="text-blue-900">
                        Email: <br /> <span className="font-bold">{user?.Email}</span>
                      </p>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box>
                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                    Other Information: <br />
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <p className="text-blue-900">
                        Country: <br /> <span className="font-bold">{user?.Country}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <p className="text-blue-900">
                        State: <br /> <span className="font-bold">{user?.State}</span>
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <p className="text-blue-900">
                        Address: <br /> <span className="font-bold">{user?.Address}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <p className="text-blue-900">
                        NIN: <br /> <span className="font-bold">{user?.Nin}</span>
                      </p>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={12}>
              <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-3">
                <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                  Verification Status
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <p className="text-blue-900">Status</p>
                    <Chip
                      label={
                        user?.VerifiedUser === 'completed'
                          ? 'Verified'
                          : user?.VerifiedUser === 'Not Verified'
                          ? 'Not Verified'
                          : user?.VerifiedUser === 'pending'
                          ? 'Pending'
                          : null
                      }
                      size="small"
                      color={
                        user?.VerifiedUser === 'completed'
                          ? 'success'
                          : user?.VerifiedUser === 'Not Verified'
                          ? 'error'
                          : user?.VerifiedUser === 'pending'
                          ? 'warning'
                          : null
                      }
                      icon={
                        <img
                          src={
                            user?.VerifiedUser === 'completed'
                              ? verifiedImg
                              : user?.VerifiedUser === 'Not Verified'
                              ? unverifiedImg
                              : user?.VerifiedUser === 'pending'
                              ? pendingImg
                              : null
                          }
                          alt="verified img"
                          className="w-4 h-4"
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <p className="text-blue-900">
                      Requested At: <br />{' '}
                      <span className="font-bold">
                        {new Date(user?.RequestedVerification).toLocaleString('en-US', {
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric',
                        })}
                      </span>
                    </p>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <p className="text-blue-900">
                      Verified At: <br />{' '}
                      <span className="font-bold">
                        {user?.VerifiedAt
                          ? new Date(user?.RequestedVerification).toLocaleString('en-US', {
                              day: 'numeric',
                              month: 'long',
                              year: 'numeric',
                            })
                          : 'Not yet Verified'}
                      </span>
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <p className="text-blue-900">
                      Joined LYL At: <br />{' '}
                      <span className="font-bold">
                        {new Date(user?.createdAt).toLocaleString('en-US', {
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric',
                        })}
                      </span>
                    </p>
                  </Grid>
                </Grid>

                <Box className="mt-5">
                  {user?.VerifiedUser === 'pending' ? (
                    <Button
                      variant="outlined"
                      size="small"
                      color="success"
                      sx={{
                        textTransform: 'none',
                        fontWeight: 'bold',
                      }}
                      onClick={HandleVerify}
                    >
                      {isLoading ? 'Please wait...' : 'Verify User'}
                    </Button>
                  ) : user?.VerifiedUser === 'completed' ? (
                    <Button
                      variant="outlined"
                      size="small"
                      color="error"
                      sx={{
                        textTransform: 'none',
                        fontWeight: 'bold',
                      }}
                      onClick={HandleUnverify}
                    >
                      {isLoading ? 'Please wait...' : 'Unverify User'}
                    </Button>
                  ) : null}
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default User;
