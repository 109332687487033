import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import agentService from './agentService';
import { logoutError } from '../auth/authService';
import { createAsyncThunkWithHandler } from '../api';

const initialState = {
  agent: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
};

export const getAgent = createAsyncThunkWithHandler('agent/getAgent', async (agentId, thunkAPI) => {
  const token = thunkAPI.getState().adminAuth.user.token;
  const id = thunkAPI.getState().adminAuth.user.id;
  const data = await agentService.getAgent(token, id, agentId);

  return data;
});

export const updateAgentStatus = createAsyncThunkWithHandler('agent/updateAgentStatus', async (agentId, thunkAPI) => {
  const token = thunkAPI.getState().adminAuth.user.token;
  const id = thunkAPI.getState().adminAuth.user.id;
  const data = await agentService.updateAgentStatus(token, id, agentId);

  return data;
});

export const unVerifyAgent = createAsyncThunkWithHandler('agent/unVerifyAgent', async (agentId, thunkAPI) => {
  const token = thunkAPI.getState().adminAuth.user.token;
  const id = thunkAPI.getState().adminAuth.user.id;
  const data = await agentService.UnverifyAgentStatus(token, id, agentId);

  return data;
});

const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAgent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.agent = action.payload;
        state.isSuccess = true;
      })
      .addCase(getAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.agent = null;
        state.isSuccess = false;
      })
      .addCase(updateAgentStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAgentStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.agent = action.payload;
        state.isSuccess = true;
        state.message = 'This Agent has now been Verified';
      })
      .addCase(updateAgentStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.agent = null;
        state.isSuccess = false;
      })
      .addCase(unVerifyAgent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(unVerifyAgent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.agent = action.payload;
        state.isSuccess = true;
        state.message = 'This Agent has now been Unverified';
      })
      .addCase(unVerifyAgent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.agent = null;
        state.isSuccess = false;
      });
  },
});

export const { reset } = agentSlice.actions;
export default agentSlice.reducer;
