import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import docServices from './landdocumentService';
import { logoutError } from '../auth/authService';
import { createAsyncThunkWithHandler } from '../api';

const docs = JSON.parse(localStorage.getItem('docs'));

const initialState = {
  docs: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

export const fetchUnverifiedDocsLand = createAsyncThunkWithHandler(
  'documents/fetchUnverifiedDocsLand',
  async (_, thunkAPI) => {
    const { token, id } = thunkAPI.getState().adminAuth.user;
    const response = await docServices.fetchUnverifiedDocsLand(token, id);
    return response;
  }
);

export const fetchUnverifiedSurveyLand = createAsyncThunkWithHandler(
  'documents/fetchUnverifiedSurveyLand',
  async (_, thunkAPI) => {
    const { token, id } = thunkAPI.getState().adminAuth.user;
    const response = await docServices.fetchUnverifiedSurveyLand(token, id);
    return response;
  }
);

export const VerifiedSurveyLand = createAsyncThunkWithHandler('documents/VerifiedSurveyLand', async (_, thunkAPI) => {
  const { token, id } = thunkAPI.getState().adminAuth.user;
  const response = await docServices.VerifiedSurveyLand(token, id);
  return response;
});

export const fetchPendingDocsLand = createAsyncThunkWithHandler(
  'documents/fetchPendingDocsLand',
  async (_, thunkAPI) => {
    const { token, id } = thunkAPI.getState().adminAuth.user;
    const response = await docServices.fetchPendingDocsLand(token, id);

    return response;
  }
);

export const fetchVerifiedDocsLand = createAsyncThunkWithHandler(
  'documents/fetchVerifiedDocsLand',
  async (_, thunkAPI) => {
    const { token, id } = thunkAPI.getState().adminAuth.user;
    const response = await docServices.VerifiedDocsLand(token, id);

    return response;
  }
);

export const FetchVerifiedSurveyLand = createAsyncThunkWithHandler(
  'documents/FetchVerifiedSurveyLand',
  async (_, thunkAPI) => {
    const { token, id } = thunkAPI.getState().adminAuth.user;
    const response = await docServices.VerifiedSurveyLand(token, id);

    return response;
  }
);

const LanddocumentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUnverifiedDocsLand.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchUnverifiedDocsLand.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = [];
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchUnverifiedSurveyLand.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchUnverifiedSurveyLand.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchUnverifiedSurveyLand.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = [];
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(FetchVerifiedSurveyLand.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(FetchVerifiedSurveyLand.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(FetchVerifiedSurveyLand.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = [];
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchPendingDocsLand.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPendingDocsLand.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchPendingDocsLand.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = [];
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(fetchVerifiedDocsLand.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchVerifiedDocsLand.fulfilled, (state, action) => {
        state.isLoading = false;
        state.docs = action.payload;
        state.isError = false;
        state.message = '';
        state.isSuccess = true;
      })
      .addCase(fetchVerifiedDocsLand.rejected, (state, action) => {
        state.isLoading = false;
        state.docs = [];
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      });
  },
});

export const { reset } = LanddocumentsSlice.actions;
export default LanddocumentsSlice.reducer;
