import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initialSurveyorPayment } from '../features/payments/paymentSlice';

const usePaystackTransfer = ({ publicKey, onTransferInitiated, fullname, amount }) => {
  const [accountNumber, setAccountNumber] = useState('');
  const [bankCode, setBankCode] = useState('');
  const [error, setError] = useState('');
  const [banks, setBanks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // New states for OTP handling
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState('');
  const [transferCode, setTransferCode] = useState('');

  useEffect(() => {
    fetchBanks();
  }, []);

  const testAccountInfo = `
      Test Account Numbers:
      - 0000000000: Always successful
      - 0000000001: Insufficient balance
      - 0000000002: Failed transfer
      Use any valid Nigerian bank code (e.g., 057 for Zenith Bank)
    `;

  const fetchBanks = async () => {
    try {
      const response = await fetch('https://api.paystack.co/bank');
      const data = await response.json();
      if (data.status) {
        setBanks(data.data);
      } else {
        setError('Failed to fetch banks. Please try again.');
      }
    } catch (error) {
      setError('An error occurred while fetching banks.');
      console.error('Fetch banks error:', error);
    }
  };

  const createTransferRecipient = async () => {
    const response = await fetch('https://api.paystack.co/transferrecipient', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${publicKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: 'nuban',
        name: fullname,
        account_number: accountNumber,
        bank_code: bankCode,
        currency: 'NGN',
      }),
    });
    const data = await response.json();
    if (!data.status) {
      throw new Error(data.message);
    }
    return data.data.recipient_code;
  };

  const initiateTransfer = async (recipientCode) => {
    const response = await fetch('https://api.paystack.co/transfer', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${publicKey}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        source: 'balance',
        reason: `Salary payment for ${fullname}`,
        amount: parseFloat(amount) * 100,
        recipient: recipientCode,
      }),
    });
    const data = await response.json();
    if (!data.status) {
      throw new Error(data.message);
    }
    return data.data;
  };

  // New function to handle OTP finalization
  const finalizeTransfer = async () => {
    try {
      const response = await fetch('https://api.paystack.co/transfer/finalize_transfer', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${publicKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          transfer_code: transferCode,
          otp,
        }),
      });
      const data = await response.json();
      if (!data.status) {
        throw new Error(data.message);
      }

      return data.data;
    } catch (error) {
      throw new Error('Failed to finalize transfer: ');
    }
  };

  const handleTransfer = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    if (!accountNumber || !bankCode) {
      setError('Please fill in all fields');
      setIsLoading(false);
      return;
    }

    try {
      const recipientCode = await createTransferRecipient();
      const transferData = await initiateTransfer(recipientCode);

      if (transferData.status === 'otp') {
        setTransferCode(transferData.transfer_code);
        setShowOtpInput(true);
      } else {
        onTransferInitiated(transferData);
      }
    } catch (error) {
      setError(error.message || 'An error occurred. Please try again.');
      console.error('Transfer error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const finalizedData = await finalizeTransfer();
      onTransferInitiated(finalizedData);
    } catch (error) {
      setError(error.message || 'Failed to verify OTP. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    accountNumber,
    setAccountNumber,
    bankCode,
    setBankCode,
    error,
    setError,
    banks,
    Loading: isLoading,
    showOtpInput,
    otp,
    setOtp,
    handleTransfer,
    handleOtpSubmit,
    testAccountInfo,
  };
};

export default usePaystackTransfer;
