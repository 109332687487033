import axios from 'axios';
import { API_URL } from '../api';

const getAgent = async (token, id, agentId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}/agents/agent/${agentId}/${id}`, config);

  return response.data;
};

const updateAgentStatus = async (token, id, agentId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(`${API_URL}/agents/agent/verify/${agentId}/${id}`, '', config);

  if (response.data) {
    localStorage.setItem('agentsFromAdmin', JSON.stringify(response.data));
  }

  return response.data;
};

const UnverifyAgentStatus = async (token, id, agentId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(`${API_URL}/agents/agent/unverify/${agentId}/${id}`, '', config);

  if (response.data) {
    localStorage.setItem('agentsFromAdmin', JSON.stringify(response.data));
  }

  return response.data;
};
const agentService = { getAgent, updateAgentStatus, UnverifyAgentStatus };
export default agentService;
