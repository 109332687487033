import axios from 'axios';
import { API_URL } from '../api';

const fetchAgents = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/agents/${id}`, config);

  if (response.data) {
    localStorage.setItem('agents', JSON.stringify(response.data));
  }
  return response.data;
};

const fetchUnverifiedAgents = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/agents/pending/${id}`, config);

  if (response.data) {
    localStorage.setItem('agents', JSON.stringify(response.data));
  }
  return response.data;
};

const agentServices = {
  fetchAgents,
  fetchUnverifiedAgents,
};

export default agentServices;
