import axios from 'axios';
import { API_URL } from '../api';

const fetchUsers = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/users/${id}`, config);

  if (response.data) {
    localStorage.setItem('lyl-users', JSON.stringify(response.data));
  }
  return response.data;
};

const fetchUnverifiedUsers = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URL}/users/pending/${id}`, config);

  if (response.data) {
    localStorage.setItem('lyl-users', JSON.stringify(response.data));
  }
  return response.data;
};

const userServices = {
  fetchUsers,
  fetchUnverifiedUsers,
};

export default userServices;
