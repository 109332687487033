import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import { reset } from '../features/payments/paymentSlice';

const usePaystackTransferSliceAction = ({ handleClose }) => {
  const { isLoading, isSuccess, isError, message } = useSelector((state) => state.payment);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      handleClose();
      toast.success(message, {
        onClose: () => {
          navigate(0);
        },
      });

      dispatch(reset());
    }
    if (isError) {
      toast.error(message);
      dispatch(reset());
    }
  }, [isSuccess, isError, message]);

  return { isLoading };
};

export default usePaystackTransferSliceAction;
